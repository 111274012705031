import { graphql } from 'gatsby';
import { HeadInner } from '../../../components/Head';
import DocumentsTemplate from '../../../template/DocumentsTemplate';
import { ServiceCode } from '../../../util/post';

export const Head: React.FC<{
  data: GatsbyTypes.DocumentQueryQuery;
}> = (props) => {
  const title = props.data.contentfulDocument?.title;
  const slug = props.data.contentfulDocument?.slug;
  return (
    <HeadInner
      path={`/documents/${slug}`}
      title={title}
      description={props.data.contentfulDocument?.description?.description}
    />
  );
};

const Documents: React.FC<{
  data: GatsbyTypes.DocumentQueryQuery;
}> = (props) => {
  const document = props.data.contentfulDocument;

  const requiredList = [
    'company',
    'employees',
    'email',
    'lastname',
    'firstname',
    'department',
    'phone',
    'industry',
  ];

  if (!document) return null;
  return (
    <DocumentsTemplate
      type='whitepaper'
      title={document.title}
      coverImage={document.coverImage}
      description={document.description}
      requiredList={requiredList}
      slug={document.slug}
      learningPoints={document.learning_points}
      serviceCodes={
        document.serviceCodes ? (document.serviceCodes as ServiceCode[]) : []
      }
    />
  );
};

export const query = graphql`
  query DocumentQuery($id: String) {
    contentfulDocument(id: { eq: $id }) {
      title
      slug
      coverImage {
        gatsbyImageData
        title
      }
      description {
        description
      }
      learning_points
      serviceCodes
    }
  }
`;

export default Documents;
